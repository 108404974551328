import * as React from 'react';
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { navigate } from 'gatsby';
import Layout from '@/container/Layout';
import TextInput from '@/components/formFields/TextInput';
import Password from '@/components/formFields/Password';
import Notification from '@/components/user/Notification';
import Button from '@/components/buttons/Button';
import Avatar from '@/components/user/Avatar';
import Text from '@/utils/Text';
import Link from '@/utils/Link';
import useFocusOnFirstInput from '@/hooks/useFocusOnFirstInput';
import useFormState from '@/hooks/useFormState';
import * as m from '@/utils/messages';
import { unconfirmedSupportNumbers } from '@/utils/unconfirmedSupportNumbers';
import ReCAPTCHA from "react-google-recaptcha";
import { func, bool } from 'prop-types';

function PasswordError() {
	return (
		<Text as="span">
			Try again or{' '}
			<Link
				className="text-white underline"
				to="/forgotten-password/"
				data-testid="reset-password-link"
				onClick={e => {
					e.stopPropagation();
				}}
			>
				reset your password
			</Link>
		</Text>
	);
}

function EmailError() {
	return <Text as="span">{m.LOGIN_EMAIL_ERROR}</Text>;
}

function Login({ ssoOnly, onLoginSuccess }) {
	const {
		register,
		handleSubmit,
		errors,
		formState,
		setError,
		setValue
	} = useForm({
		mode: 'onBlur',
		reValidateMode: 'onChange'
	});
	const formRef = React.useRef();

	const {
		loggedIn,
		loginError,
		passwordChanged,
		benefitBrandName,
		supportEmail,
		supportNumber,
		avatar,
		cmsData
	} = useSelector(state => state.user);

	const isSSOActive = cmsData?.sso_access_only;

	const [showRecaptcha, setShowRecaptcha] = React.useState(false);
	const [showPasswordInput, setShowPasswordInput] = React.useState(false);
	const [emailInputError, setEmailInputError] = React.useState(null);
	const [isCheckingSSO, setIsCheckingSSO] = React.useState(false);

	const dispatch = useDispatch();

	useFocusOnFirstInput(formRef);

	React.useEffect(() => {
		if (loggedIn || (window.localStorage.getItem('loggedIn') === 'true' && window.localStorage.getItem('loginCredentials') !== null)) {
			onLoginSuccess ? onLoginSuccess() : navigate('/app/home/');
		}
	}, [loggedIn, dispatch, isSSOActive, onLoginSuccess]);

	React.useEffect(() => {
		setEmailInputError(null);
		setShowRecaptcha(false);
	}, [showPasswordInput]);

	const emailPattern = /^[A-Z0-9._%'+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

	const emailFieldProps = {
		required: {
			value: true,
			message: m.REQUIRED_FIELD
		},
		pattern: {
			value: emailPattern,
			message: m.ENTER_VALID_EMAIL
		}
	};

	const validateEmail = (email) => {
		if (!email || email.trim() === '') {
			setEmailInputError(m.REQUIRED_FIELD);
			return false;
		}

		const isValid = emailPattern.test(email);
		if (!isValid) {
			setEmailInputError(m.ENTER_VALID_EMAIL);
			return false;
		}

		setEmailInputError(null);
		return true;
	};

	const onSubmit = async data => {
		if (!showPasswordInput) {
			if (validateEmail(data.email)) {
				setIsCheckingSSO(true);
				const response = await dispatch({
					type: 'user/LOGIN_URL',
					payload: { email: data.email }
				});
				setIsCheckingSSO(false);
	
				if (!response.hasSSO) {
					setShowPasswordInput(true);
				}
			}
			return;
		}
	
		const response = await dispatch({
			type: 'user/REQUEST_ACCESS_TOKEN',
			payload: data
		});
	
		setShowRecaptcha(!!response?.showRecaptcha);
	};

	useFormState(formState.isSubmitting);

	const showFormErrors =
		formState.submitCount > 0 &&
		!!loginError &&
		!formState.isSubmitting &&
		loginError !== 'Please accept cookies before logging in';

	React.useLayoutEffect(() => {
		if (showFormErrors) {
			setError('email', 'notMatch', <EmailError />);
			setError('password', 'notMatch', <PasswordError />);
		}
	}, [loginError, setError, formState.isSubmitting, showFormErrors]);

	return (
		<Layout className="flex-grow pt-lg lg:pt-2xl">
			<Helmet>
				<title>{`Login | ${benefitBrandName}`}</title>
			</Helmet>

			{passwordChanged && (
				<Notification
					level="success"
					className="text-center -mt-md mb-lg lg:-mt-lg lg:mb-xl font-h-light"
					data-testid="notification"
				>
					{m.PASSWORD_CHANGED}
				</Notification>
			)}

			{loginError && (
				<Notification
					level="error"
					className="text-center -mt-md mb-lg lg:-mt-lg lg:mb-xl font-h-light"
					data-testid="error"
				>
					<Text
						as="p"
						className="w-full text-mob-sm"
						dangerouslySetInnerHTML={{
							__html: loginError
						}}
					/>
				</Notification>
			)}

			{!ssoOnly && (
				<div className="w-full grid-flow-row-dense mx-auto pb-lg max-w-login lg:max-w-none lg:grid lg:grid-cols-12 lg:col-gap-2xl ie:flex ie:flex-wrap">
					<div className="mb-lg lg:col-start-2 lg:col-end-7 lg:mb-2xl ie:w-1/2 ie:px-xl">
						{avatar && (
							<Avatar
								image={avatar}
								className={'w-wilb-mob md:w-wilb h-auto mb-xl'}
							/>
						)}
						<Text
							as="h1"
							className="w-full leading-tight font-h-light text-mob-2xl md:text-2xl mb-lg"
						>
							{m.WELCOME_MESSAGE(benefitBrandName)}
						</Text>

						<div className="hidden lg:block h-150"></div>
						
						<Text
							as="p"
							className="w-full text-mob-sm hidden lg:block"
							dangerouslySetInnerHTML={{
								__html: (unconfirmedSupportNumbers.includes(supportNumber) ?
									m.TROUBLE_LOGGING_IN_WITHOUT_TEL({ supportEmail }) : m.TROUBLE_LOGGING_IN_WITH_TEL({ supportEmail, supportNumber }))
							}}
						/>
					</div>

					<form
						ref={formRef}
						data-testid={showPasswordInput ? "login-form" : "email-form"}
						noValidate
						method="post"
						onSubmit={handleSubmit(onSubmit)}
						className="lg:col-start-8 lg:col-end-11 ie:w-1/2 ie:px-2xl"
					>
						<fieldset className="mb-xl lg:mb-0">
							<div className="mb-lg">
								<Text as="span" className="block text-mob-lg mb-lg font-h-light">
									Email address
								</Text>
								<TextInput
									id="email"
									name="email"
									type="email"
									ref={register(emailFieldProps)}
									error={emailInputError || errors?.email?.message}
									autoComplete="username"
									disabled={false}
								/>
							</div>

							{showPasswordInput && (
								<>
									<Password
										title="Password"
										id="password"
										name="password"
										ref={register({
											required: {
												value: true,
												message: <PasswordError />
											}
										})}
										error={errors?.password?.message}
										showForgttenLink
										autoComplete="current-password"
										showInputClassName="md:absolute md:bottom-md md:left-100"
									/>

									{errors.recaptcha && (
										<Notification size="sm" level="error" className="mb-sm p-sm">
											Please confirm that you are not a robot!
										</Notification>
									)}

									{(showRecaptcha && !formState.isSubmitting) && (
										<ReCAPTCHA
											className="mb-lg"
											sitekey={RECAPTCHA_PUBLIC_KEY}
											onChange={value => setValue('recaptcha', value)}
											data-testid="recaptcha"
										/>
									)}
								</>
							)}
						</fieldset>

						<div className="mb-xl">
							<Button
								data-testid={showPasswordInput ? "login-submit" : "next-button"}
								className="px-2xl"
								type="submit"
								disabled={formState.isSubmitting || isCheckingSSO}
							>
								{isCheckingSSO ? 'Loading...' : (showPasswordInput ? 'Login' : 'Next')}
							</Button>
						</div>
					</form>
					
					<div className="lg:hidden mb-lg ie:w-1/2 ie:px-2xl">
						<Text
							as="p"
							className="w-full text-mob-sm"
							dangerouslySetInnerHTML={{
								__html: (unconfirmedSupportNumbers.includes(supportNumber) ?
									m.TROUBLE_LOGGING_IN_WITHOUT_TEL({ supportEmail }) : m.TROUBLE_LOGGING_IN_WITH_TEL({ supportEmail, supportNumber }))
							}}
						/>
					</div>
				</div>
			)}
		</Layout>
	);
}

Login.propTypes = {
	ssoOnly: bool,
	onLoginSuccess: func
};

export default Login;